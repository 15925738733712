import { Fragment } from "react";
import "./NavBar.css";
import Search from "components/Search/Search";
import Cart from "components/Picture/Cart";

export default function NavBar(params) {
  const navOpen = params.mobileNavOpen;
  const setNavOpen = params.setMobileNavOpen;

  return (
    <Fragment>
      <div className="navbar">
        <Navs />
      </div>
      {navOpen ? (
        <div className="mobilenavbar">
          <x
            onClick={() => {
              setNavOpen(false);
            }}
          >
            &#x2715;
          </x>
          <Navs />
          <div className="shop">
            <Cart />
            <Search />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

function Navs() {
  return (
    <Fragment>
      <a href="/" className="row-center">
        <span className="nav">HOME</span>
      </a>
      <a href="/products" className="row-center">
        <span className="nav">JEWELRY</span>
      </a>
      <a href="/products?tags=Featured Deals" className="row-center">
        <span className="nav">FEATURED DEALS</span>
      </a>
      <a href="/products?tags=New Arrivals" className="row-center">
        <span className="nav">NEW ARRIVALS</span>
      </a>
      <a href="/about" className="row-center">
        <span className="nav">ABOUT</span>
      </a>
      <a href="/contact" className="row-center">
        <span className="nav">CONTACT</span>
      </a>
    </Fragment>
  );
}
