import { attributes as text } from "../../content/components/reviews.md";
import { useState } from "react";
import { useEffect } from "react";
import "./Reviews.css";

export default function Reviews() {
  const [state, setState] = useState(0);

  useEffect(() => {
    let intId;
    if (text.reviews)
      intId = setInterval(() => {
        setState((prevState) =>
          prevState < text.reviews.length - 1 ? prevState + 1 : 0
        );
      }, 5000);

    return () => {
      clearInterval(intId);
    };
    //eslint-disable-next-line
  }, []);
  return (
    <div style={{ marginBottom: "3%" }}>
      <div className="row-center">
        <h2>{text.title}</h2>
      </div>
      <div className="reviews row-center">
        {text.reviews ? (
          text.reviews.map((review, i) => {
            return (
              i === state && (
                <div className="review column-center">
                  <div>
                    <b>{review.text}</b>
                  </div>
                  <div>
                    -<i>{review.customer}</i>
                  </div>
                </div>
              )
            );
          })
        ) : (
          <div className="reivew">
            <div className="row-center">Nothing Yet...</div>
          </div>
        )}
      </div>
      <div style={{ marginTop: "5%" }} className="row-center">
        Leave a review&nbsp;
        <u
          onClick={() => {
            window.location.href = "/contact";
          }}
          style={{ cursor: "pointer" }}
        >
          here
        </u>{" "}
      </div>
    </div>
  );
}
