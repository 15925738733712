import { useState } from "react";
import { useEffect } from "react";
import Picture from "./Picture";

export default function Cart() {
  const [count, setCount] = useState(0);
  const getCartItems = () => {
    setCount((JSON.parse(window.localStorage.getItem("cart")) ?? []).length);
  };
  useEffect(() => {
    getCartItems();
    window.addEventListener("cartUpdate", getCartItems);

    return () => {
      window.removeEventListener("cartUpdate", getCartItems);
    };
    //eslist-disable-next-line
  }, []);
  return (
    <div style={{ width: "3rem" }}>
      <a className="bag" href="/cart">
        {(JSON.parse(window.localStorage.getItem("cart")) ?? []).length > 0 ? (
          <span
            style={{
              width: "1em",
              height: "1em",
              backgroundColor: "whitesmoke",
              borderRadius: "100%",
              textAlign: "center",
              float: "right",
            }}
          >
            {count}
          </span>
        ) : (
          <span
            style={{
              width: "1em",
              height: "1em",
              borderRadius: "100%",
              textAlign: "center",
              position: "fixed",
              float: "right",
            }}
          ></span>
        )}
        <Picture src={"images/shopping-bag.png"} alt="shopping cart" />
      </a>
    </div>
  );
}
