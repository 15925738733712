import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import NavBar from "./components/NavBar/NavBar";

import "./content/animations.css";
import { useState } from "react";
import Home from "screens/home/Home";
import Footer from "components/Footer/Footer";
import NotFound from "screens/notfound/NotFound";
import Products from "screens/products/Products";
import About from "screens/About/About";
import Inventory from "screens/inventory/Inventory";
import Cart from "screens/cart/Cart";
import Unsubscribe from "screens/unsubscribe/Unsubsribe";
import Contact from "screens/contact/Contact";
import Receipt from "screens/receipt/Receipt";
import Orders from "screens/orders/Orders";
import Login from 'screens/login/Login';

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState();
  return (
    <Fragment>
      <Router>
        <div className="body-content">
          <Header
            setMobileNavOpen={setMobileNavOpen}
            mobileNavOpen={mobileNavOpen}
          />
          <NavBar
            setMobileNavOpen={setMobileNavOpen}
            mobileNavOpen={mobileNavOpen}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="/login" element={<Login />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/newsletter/unsubscribe" element={<Unsubscribe />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </Fragment>
  );
}

export default App;
