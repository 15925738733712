import Picture from "components/Picture/Picture";
import { useState } from "react";

export default function Search(props) {
  const [value, setValue] = useState("");
  const [placeholder, setPlaceholder] = useState("Search");
  const handleClick = () => {
    if (value) {
      window.location.href =
        `/${props.page === "inventory" ? "inventory" : "products"}?search=` +
        value;
    } else {
      setPlaceholder("Enter a Value");
    }
  };
  return (
    <div className="row-center" style={{ alignItems: "center" }}>
      <input
        type="text"
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
      />
      <button
        style={{ backgroundColor: "transparent", cursor: "pointer" }}
        onClick={handleClick}
      >
        <Picture
          src="images/search.png"
          alt="search button "
          style={{ marginLeft: "5px", width: "1rem" }}
        />
      </button>
    </div>
  );
}
