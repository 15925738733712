import axios from "axios";
import Loader from "components/Picture/Loader";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./Inventory.css";
import getAuth from "shared/http/getAuth";
import ProductAddEdit from "components/Product/ProductAddEdit";
import Search from "../../components/Search/Search";
export default function Inventory() {
  const [authed, setAuthed] = useState(false);
  const [inventory, setInventory] = useState();
  const [loading, setLoading] = useState(true);
  const [responseMessge, setResponseMessage] = useState("");
  const loc = useLocation();
  const [paginationSelected, setPaginationSelected] = useState(0);
  const [itemsPerPage] = useState(20);

  const getInventory = () => {
    setAuthed(getAuth());
    setLoading(true);
    axios
      .get(
        "/.netlify/functions/products" +
          loc.search.replace("%20", "").replace("null", "")
      )
      .then((res) => setInventory(res.data))
      .finally(() => setLoading(false));
  };

  const handleDelete = (id) => {
    axios
      .delete("/.netlify/functions/products?id=" + id, {
        headers: { authorization: getAuth() },
      })
      .then(() => {
        getInventory();
      });
  };

  const handleSave = (product) => {
    const data = new FormData();
    data.append("image", product.image);
    data.append("bodyData", JSON.stringify(product));
    if (product._id) {
      axios
        .put("/.netlify/functions/products?id=" + product._id, data, {
          headers: {
            authorization: getAuth(),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          reponseMessageSetter(product._id, res.status);
        })
        .catch((err) => {
          alert(err);
          reponseMessageSetter(product._id, err.status);
        });
    } else {
      axios
        .post("/.netlify/functions/products", data, {
          headers: {
            authorization: getAuth(),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          getInventory();
          reponseMessageSetter(res.data._id, res.status);
        });
    }
  };
  const reponseMessageSetter = (id, statusCode) => {
    switch (statusCode) {
      case 200:
        setResponseMessage({ id: id, message: "Success" });
        break;
      case 400:
      case 405:
        setResponseMessage({ id: id, message: "Bad Request" });
        break;
      case 401:
        setResponseMessage({ id: id, message: "Unauthorized" });
        break;
      case 500:
        setResponseMessage({ id: id, message: "Error" });
        break;
      default:
        setResponseMessage({ id: id, message: statusCode });
        break;
    }
  };

  useEffect(() => {
    getInventory();

    setAuthed(getAuth());

    window.netlifyIdentity.on("login", getInventory);
    window.netlifyIdentity.on("logout", getInventory);

    return () => {
      window.netlifyIdentity.off("login", getInventory);
      window.netlifyIdentity.off("logout", getInventory);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="inventory" data-netlify-identity-button></div>
      <div className="row-center">{authed && <h4>Add A Product</h4>}</div>
      <div>{authed && <ProductAddEdit handleSave={handleSave} />}</div>
      <div className="row-center">{authed && <h4>Saved inventory</h4>}</div>
      <div className="row-center" style={{ marginBottom: "2%" }}>
        {authed && <Search page="inventory" />}
      </div>
      <div className="row-wrap">
        {loading ? (
          <Loader />
        ) : inventory && authed ? (
          inventory?.length > 0 ? (
            inventory.map((product, i) => {
              return (
                <Fragment>
                  {i >= paginationSelected * itemsPerPage &&
                  i < (paginationSelected + 1) * itemsPerPage ? (
                    <ProductAddEdit
                      key={i}
                      product={product}
                      handleSave={handleSave}
                      handleDelete={handleDelete}
                      responseMessge={responseMessge}
                    />
                  ) : null}
                </Fragment>
              );
            })
          ) : (
            "Nothing Found"
          )
        ) : null}
      </div>
      <div className="row-center">
        {inventory && authed && inventory.length && paginationSelected >= 0
          ? [...Array(Math.round(inventory.length / itemsPerPage)).keys()].map(
              (v) =>
                v <=
                  paginationSelected +
                    (paginationSelected === 0
                      ? 4
                      : paginationSelected === 1
                      ? 3
                      : 2) &&
                v >=
                  paginationSelected -
                    (paginationSelected ===
                    Math.floor(inventory.length / itemsPerPage)
                      ? 4
                      : paginationSelected + 1 ===
                        Math.floor(inventory.length / itemsPerPage)
                      ? 3
                      : 2) ? (
                  <div
                    key={v}
                    className={`nav pagination-nav ${
                      v === paginationSelected ? " pagination-selected" : ""
                    }`}
                    onClick={() => {
                      setPaginationSelected(v);
                    }}
                  >
                    {v + 1}
                  </div>
                ) : null
            )
          : null}
      </div>
    </div>
  );
}
