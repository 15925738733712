import Picture from "components/Picture/Picture";
import ImageSelect from "components/ImageSelect/ImageSelect";
import { Fragment, useState } from "react";
import "./Product.css";

export default function ProductAddEdit(props) {
  const [product, setProduct] = useState(props.product ?? {});
  const [addImage, setAddImage] = useState(false);
  const [required, setRequired] = useState({});

  const handleChange = (e) => {
    setProduct((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div style={{ width: "50%" }}>
      <div className="edit row">
        {
          <Fragment>
            <div
              style={{
                width: "25%",
                marginRight: "5%",
              }}
              className="column"
            >
              {product.tempImage && (
                <img
                  src={product.tempImage}
                  alt="temp product"
                  style={{
                    width: "100%",
                    maxHeight: "auto",
                  }}
                />
              )}
              {product.image && !product.tempImage && (
                <Picture
                  src={product.image}
                  alt="product"
                  style={{
                    width: "100%",
                    maxHeight: "auto",
                  }}
                />
              )}
              <input
                type="file"
                onChange={(e) => {
                  setProduct((prevState) => ({
                    ...prevState,
                    image: e.target.files[0],
                    tempImage: URL.createObjectURL(e.target.files[0]),
                  }));
                }}
              />
            </div>
            <div
              className="column"
              style={{
                width: "40%",
                maxHeight: "auto",
              }}
            >
              <div className="row">
                <ProductInput
                  required={required}
                  handleChange={handleChange}
                  product={product}
                  label="Title:"
                  name="title"
                />
              </div>
              <div className="row">
                <span style={{ marginLeft: "35%", width: "60%", color: "red" }}>
                  {required && required["title"] ? `Title is required` : null}
                </span>
              </div>
              <div className="row">
                <ProductInput
                  required={required}
                  handleChange={handleChange}
                  product={product}
                  label="Price:"
                  name="price"
                  type="number"
                />
              </div>
              <div className="row">
                <span style={{ marginLeft: "35%", width: "60%", color: "red" }}>
                  {required && required["price"] ? `Price is required` : null}
                </span>
              </div>
              <div className="row">
                <ProductInput
                  handleChange={handleChange}
                  product={product}
                  label="Sale Price:"
                  name="salePrice"
                  type="number"
                />
              </div>
              <div className="row">
                <ProductInput
                  required={required}
                  handleChange={handleChange}
                  product={product}
                  label="Quantity:"
                  name="quantity"
                  type="number"
                />
              </div>
              <div className="row">
                <span style={{ marginLeft: "35%", width: "60%", color: "red" }}>
                  {required && required["quantity"]
                    ? `Quantity is required`
                    : null}
                </span>
              </div>
              <div className="row">
                <span style={{ width: "33%" }}>Description: </span>
                <textarea
                  rows={5}
                  style={{ padding: "0", margin: "0", width: "67%" }}
                  onChange={handleChange}
                  name="description"
                  value={product["description"]}
                />
              </div>
              <div className="row">
                <span style={{ width: "33%" }}>Category:</span>
                <select
                  style={{ width: "50%" }}
                  onChange={handleChange}
                  name="category"
                  value={product.category}
                >
                  <option value="null">Select Category</option>
                  <option value="Earings">Earings</option>
                  <option value="Sets">Sets</option>
                  <option value="Pendants">Pendants</option>
                  <option value="Rings">Rings</option>
                  <option value="Bracelets">Bracelets</option>
                  <option value="Necklaces">Necklaces</option>
                  <option value="Framed Art">Framed Art</option>
                  <option value="Rocks">Rocks</option>
                  <option value="Anesidora">Anesidora</option>
                </select>
              </div>
              <div className="row">
                <span style={{ marginLeft: "35%", width: "60%", color: "red" }}>
                  {required && required["category"]
                    ? `Category is required`
                    : null}
                </span>
              </div>
              <div className="row">
                <ProductInput
                  handleChange={handleChange}
                  product={product}
                  label="Tags:"
                  name="tags"
                />
              </div>
              <div className="row">
                <div style={{ marginLeft: "33%", width: "66%" }}>
                  <ProductButton
                    text={"Save"}
                    onClick={() => {
                      if (
                        checkRequired(product, setRequired) &&
                        window.confirm("Are you sure you want to save?")
                      ) {
                        props.handleSave(product);
                      }
                    }}
                  />
                  {props.handleDelete ? (
                    <ProductButton
                      text="Delete"
                      onClick={() => {
                        window.confirm(
                          `Are you sure you want to delete ${product.title}?`
                        ) && props.handleDelete(product._id);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Fragment>
        }
        {addImage ? (
          <ImageSelect
            select={(i) => {
              setProduct((prevState) => ({ ...prevState, image: i }));
            }}
            close={() => setAddImage(false)}
          />
        ) : null}
      </div>
      <div className="row edit" style={{ marginBottom: "5%" }}>
        {props.responseMessge?.id === product._id
          ? props.responseMessge?.message
          : null}
      </div>
    </div>
  );
}

function ProductInput(props) {
  return (
    <Fragment>
      <span style={{ width: "33%" }}>{props.label}</span>
      <input
        style={{ width: "67%", margin: 0 }}
        type={props.type ?? "text"}
        onChange={(e) => {
          props.handleChange(e, props.required !== undefined);
        }}
        name={props.name}
        value={props.product[props.name]}
      />
    </Fragment>
  );
}
function ProductButton(props) {
  return (
    <input
      type="button"
      style={{ width: "45%", marginRight: "2.5%" }}
      onClick={props.onClick}
      value={props.text}
    />
  );
}

function checkRequired(product, setRequired) {
  const title = product.title;
  const price = product.price ?? -1;
  const quantity = product.quantity ?? -1;
  const category = product.category;

  setRequired({
    title: title === undefined || title === "",
    price: price < 0 || price === undefined || price === "",
    quantity: quantity < 0 || quantity === undefined || quantity === "",
    category: category === undefined || category === "" || category === "null",
  });

  return (
    title &&
    price > -1 &&
    price !== undefined &&
    price !== "" &&
    quantity > -1 &&
    quantity !== undefined &&
    quantity !== "" &&
    category &&
    category !== "null"
  );
}
