import "./Picture.css";

export default function Picture(props) {
  const src = props.src;
  const srcHover = props.srcHover;
  return props.src && props.srcHover ? (
    <div
      className={
        (props.hoverClassName ? props.hoverClassName : "") + " hover-image"
      }
    >
      <img
        onClick={props.onClick}
        src={`${process.env.PUBLIC_URL}/${src}`}
        alt={props.imgAlt}
        style={props.style}
        className={(props.className ? props.className : "") + " front"}
      />
      <img
        onClick={props.onClick}
        src={`${process.env.PUBLIC_URL}/${srcHover}`}
        alt={props.imgAlt}
        style={props.style}
        className={(props.className ? props.className : "") + " back"}
      />
    </div>
  ) : props.src ? (
    <img
      onClick={props.onClick}
      src={
        src.toString().startsWith("AWSKEY_")
          ? "/.netlify/functions/images?key=" + src
          : `${process.env.PUBLIC_URL}/${src}`
      }
      alt={props.imgAlt}
      style={props.style}
      className={props.className}
    />
  ) : null;
}
