import Picture from "components/Picture/Picture";
import "./Header.css";
import { attributes } from "../../content/components/header.md";
import Search from "components/Search/Search";
import Cart from "components/Picture/Cart";

export default function Header(params) {
  const setMobileNavOpen = params.setMobileNavOpen;

  return (
    <header>
      <div
        className="mobile-nav-icon"
        onClick={(e) => {
          setMobileNavOpen(true);
        }}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="main">
        <Picture src={attributes.logo} className="logo" alt="logo" />
        <div></div>
        <div className="shop">
          <Search />
          <Cart />
        </div>
      </div>
    </header>
  );
}
