import "./Home.css";
import { attributes as text } from "../../content/pages/home.md";
import Picture from "components/Picture/Picture";
import Collage from "components/Collage/Collage";
import NewsLetter from "components/NewsLetter/NewsLetter";
import About from "../../components/About/About";
import Categories from "components/Categories/Categories";
import Reviews from "components/Reviews/Reviews";

function Home() {
  return (
    <div className="home">
      <div
        style={{ fontSize: "25px", fontFamily: "Pacifico" }}
        className="hero-overlay"
      >
        {text.heroOverlay}
      </div>
      <Picture src={text.hero} alt="hero" className="hero-img" />
      <div className="collage">
        <div className="row-center">
          <h1>{text.collageTitle}</h1>
        </div>
        <Collage imgs={text.collage} />
      </div>
      <About text={text} />
      <Categories text={text} />
      <NewsLetter />
      <Reviews />
    </div>
  );
}

export default Home;
