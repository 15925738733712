import ContactForm from "components/ContactForm/ContactForm";
import "./Contact.css";
import { attributes } from "../../content/pages/contact.md";
export default function Contact() {
  const text = attributes;
  return (
    <div className="contact">
      <title>
        <h2>{text.title}</h2>
      </title>
      <div className="subtitle">
        <span style={{ fontSize: "large" }}>​​{text.subTitle}</span>
      </div>
      <div className="subtitle">
        <span style={{ fontSize: "medium" }}>{text.content}</span>
      </div>
      <hr />
      <section>
        <div className="form-section">
          <ContactForm />
        </div>
      </section>
    </div>
  );
}
