import Product from "components/Product/Product";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import "./Cart.css";
import getCart from "shared/http/getCart";
import axios from "axios";
import Loader from "components/Picture/Loader";
export default function Cart() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submiting, setSubmiting] = useState();
  const [error, setError] = useState(false);

  const getCartCallback = () => {
    getCart(setProducts);
    setLoading(false);
  };
  useEffect(() => {
    getCartCallback();

    window.addEventListener("cartUpdate", getCartCallback);

    return () => {
      window.removeEventListener("cartUpdate", getCartCallback);
    };
    //eslist-disable-next-line
  }, []);
  return loading ? (
    <div className="row-center">
      <Loader />
    </div>
  ) : (
    <div className="cart row">
      <div className="column left">
        {products.length > 0 ? (
          products.map((p) => {
            return (
              <div style={{ padding: "5%" }}>
                {p && <Product product={p} incart={true} />}
              </div>
            );
          })
        ) : (
          <Fragment>
            <div style={{ padding: "25%" }} className="row-center">
              No Items
            </div>
            <div className="row-center">
              <a href="/products">
                <u>Continue shopping</u>
              </a>
            </div>
          </Fragment>
        )}
      </div>
      <div className="column right">
        <h2>Your Cart</h2>
        <div className="hr">
          <hr />
        </div>
        <p>
          Item total: $
          {products.length > 0
            ? products.reduce((a, b) => {
                return a + parseInt(b.price);
              }, 0)
            : "0"}
        </p>
        <p>Shipping: ${products.length * 5}</p>
        <span>
          Sub total: $
          {products.length > 0
            ? products.reduce((a, b) => {
                return a + parseInt(b.price);
              }, 0) +
              products.length * 5
            : "0"}
        </span>
        <small>Taxes are calculated during checkout.</small>

        <div className="column" style={{ width: "100%", marginTop: "5%" }}>
          {submiting ? (
            <div>
              <Loader />
            </div>
          ) : (
            <button
              onClick={() => {
                setSubmiting(true);
                axios
                  .post("/.netlify/functions/checkout", { products })
                  .then((res) => {
                    window.location.href = res.data?.Location;
                  })
                  .catch(() => {
                    setSubmiting(false);
                    setError(true);
                  });
              }}
            >
              Check Out
            </button>
          )}
          {error ? (
            <div>
              <small style={{ wordWrap: "normal" }}>
                An error occured. You may have sold out items in your cart. If
                removing them does not solve the issue, please contact us with a
                description of the problem.
              </small>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
