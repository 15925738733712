import "./Product.css";
import Picture from "../Picture/Picture";
export default function ProductModal(props) {
  const product = props.product;

  return (
    <div className="modal">
      <div className="modal-content">
        <div
          onClick={() => {
            props.close(false);
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            cursor: "pointer",
            paddingBottom: "1rem",
          }}
        >
          <b>X</b>
        </div>
        <div className="modal-container">
          <div className="modal-section">
            <Picture
              src={product.image}
              alt="product"
              style={{
                width: "75%",
                height: "auto",
              }}
            />
          </div>
          <div className="modal-section-secondary">
            <div className="row-center">
              <h1>{product.title}</h1>
            </div>
            <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
              <hr />
            </div>
            <div className="row" style={{ textAlign: "left" }}>
              <p>{product.description}</p>
            </div>
            <div className="row">
              <p>{`Item Cost: $${product.salePrice ?? product.price}`}</p>
            </div>
            <div style={{ marginBottom: "10%" }} className="row">
              <p>Shipping: $5</p>
            </div>
            <div className="row">
              {product.quantity > 0 ? (
                props.isInCart() ? (
                  <button
                    onClick={() => {
                      props.removeFromCart();
                      props.close();
                    }}
                  >
                    Remove from cart
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      props.addToCart();
                      props.close();
                    }}
                  >
                    Add to cart
                  </button>
                )
              ) : (
                <span style={{ color: "red" }}>Sold Out</span>
              )}
            </div>
          </div>
        </div>
        <div className="row-center">
          <button
            onClick={() => {
              props.close(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
