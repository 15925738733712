import axios from "axios";
import Loader from "components/Picture/Loader";
import Picture from "components/Picture/Picture";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./Receipt.css";

export default function Receipt() {
  const [loading, setLoading] = useState(true);
  const [sucess, setSuccess] = useState(true);
  const loc = useLocation();
  const [receipt, setReceipt] = useState();

  useEffect(() => {
    const sessionId = loc.search.replace("?session_id=", "");

    axios
      .post("/.netlify/functions/receipt", { sessionId })
      .then((res) => {
        setReceipt(res.data);
        window.localStorage.removeItem("cart");
        window.dispatchEvent(new CustomEvent("cartUpdate"));
        setLoading(false);
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <div className="row-center">
      <Loader />
    </div>
  ) : sucess ? (
    <div className="receipt">
      <div className="row-center">
        <h2>Thank you for your purchase!</h2>
      </div>
      <div className="row-center">
        <h3>
          Please check your email for a confirmation. It may be in your
          spam/junk folder.
        </h3>
      </div>
      <hr style={{ marginLeft: "20%", marginRight: "20%" }} />
      <div className="row-center">
        <h4>Confirmation Number:</h4>
      </div>
      <div className="row-center">
        <span style={{ wordBreak: "break-all", width: "80%" }}>
          {receipt.id}
        </span>
      </div>
      <div className="row-center">
        <span style={{ width: "80%", marginTop: "3%" }}>
          Please allow 1-2 weeks for your items to arrive.
        </span>
      </div>
      <hr style={{ marginLeft: "20%", marginRight: "20%" }} />
      <div className="row-center">
        <h3>Items</h3>
      </div>
      <div className="row-center row-wrap">
        {receipt &&
          receipt.products?.map((product, i) => {
            return (
              <div>
                <div className="row">
                  <Picture
                    src={product.image}
                    alt="product"
                    style={{
                      width: "100%",
                      maxHeight: "30rem",
                      paddingLeft: "25%",
                      paddingRight: "25%",
                    }}
                  />
                </div>
                <div className="row-center">{product.title}</div>
              </div>
            );
          })}
      </div>
      <div style={{ marginTop: "5%" }} className="row-center">
        <a href="/">
          <u>Go to Home</u>
        </a>
      </div>
    </div>
  ) : (
    <div className="receipt">
      <div className="row-center">
        <h2>
          WARNING! An error occured please contact us through our eamil at the
          bottom of this page or the contact section of the site.
        </h2>
      </div>
    </div>
  );
}
