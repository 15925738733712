import "./About.css";
import Picture from "components/Picture/Picture";
export default function About(props) {
  const text = props.text;
  return (
    <div className="about-section">
      <div className="about-center">
        <p>{text.aboutSection}</p>
        <Picture src={text.aboutImage} alt="about us" className="about-img" />
      </div>
      <div className="row-center">
        <input
          onClick={() => {
            window.location.href = "/about";
          }}
          type="button"
          value="Read More"
          style={{ width: "45%" }}
        />
      </div>
    </div>
  );
}
