import axios from "axios";
import Product from "components/Product/Product";
import { attributes as text } from "../../content/pages/products.md";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import "./Products.css";
import { useLocation } from "react-router";
import Loader from "components/Picture/Loader";

export default function Products() {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);
  const loc = useLocation();
  const [selectValue, setSelectValue] = useState();
  const [paginationSelected, setPaginationSelected] = useState(0);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    axios
      .get(
        "/.netlify/functions/products" +
          loc.search
            .replace("Featured", "")
            .replace("Arrivals", "")
            .replace("%20", "")
            .replace("null", "")
      )
      .then((res) => setState(res.data))
      .finally(() => setLoading(false));
    setSelectValue(
      loc.search
        .replace("Featured", "")
        .replace("Arrivals", "")
        .replace("%20", "")
        .replace("null", "")
        .replace("?category=", "")
    );
  }, [loc.search]);

  return (
    <div className="products-page">
      <div style={{ paddingLeft: "20%", paddingRight: "20%" }}>
        <div style={{ textAlign: "center" }}>
          <h1>
            {loc.pathname === "/products" && loc.search === ""
              ? text.title
              : `${decodeURI(
                  loc.search
                    .replace("?", "")
                    .replace("=", "")
                    .replace("search", "Jewelery matching: ")
                    .replace("category", "")
                    .replace("null", "Jewelery")
                    .replace("tags", "")
                )}`}
          </h1>
        </div>
        <hr style={{ marginLeft: "20%", marginRight: "20%" }} />
        <h3 className="row-center">{text.subTitle}</h3>
      </div>
      {loc.search.includes("tags") !== true && (
        <Fragment>
          <div className="row-center">Filter</div>
          <div className="row-center">
            <select
              onChange={(e) => {
                window.location.href = "/products?category=" + e.target.value;
              }}
              name="category"
              value={selectValue}
            >
              <option value="null">Select a Category</option>
              <option value="Earings">Earings</option>
              <option value="Sets">Sets</option>
              <option value="Pendants">Pendants</option>
              <option value="Rings">Rings</option>
              <option value="Bracelets">Bracelets</option>
              <option value="Necklaces">Necklaces</option>
              <option value="Framed Art">Framed Art</option>
              <option value="Rocks">Rocks</option>
              <option value="Anesidora">Anesidora</option>
            </select>
          </div>
        </Fragment>
      )}
      <div className="products row-center">
        {state.length ? (
          state.map((product, i) => {
            return i >= paginationSelected * itemsPerPage &&
              i < (paginationSelected + 1) * itemsPerPage ? (
              <Product key={i} product={product} />
            ) : null;
          })
        ) : loading ? (
          <Loader />
        ) : (
          "Nothing found - Come back soon!"
        )}
      </div>
      <div className="row-center">
        {state.length && paginationSelected >= 0
          ? [...Array(Math.round(state.length / itemsPerPage)).keys()].map(
              (v) =>
                v <=
                  paginationSelected +
                    (paginationSelected === 0
                      ? 4
                      : paginationSelected === 1
                      ? 3
                      : 2) &&
                v >=
                  paginationSelected -
                    (paginationSelected ===
                    Math.floor(state.length / itemsPerPage)
                      ? 4
                      : paginationSelected + 1 ===
                        Math.floor(state.length / itemsPerPage)
                      ? 3
                      : 2) ? (
                  <div
                    key={v}
                    className={`nav pagination-nav ${
                      v === paginationSelected ? " pagination-selected" : ""
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setPaginationSelected(v);
                    }}
                  >
                    {v + 1}
                  </div>
                ) : null
            )
          : null}
      </div>
    </div>
  );
}
