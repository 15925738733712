import { useEffect, useState } from 'react';
import getAuth from 'shared/http/getAuth';
export default function Login() {
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    setAuthed(getAuth());

    window.netlifyIdentity.on('login', () => {
      setAuthed(getAuth());
    });
    window.netlifyIdentity.on('logout', () => {
      setAuthed(getAuth());
    });

    return () => {
      window.netlifyIdentity.off('login', () => {
        setAuthed(getAuth());
      });
      window.netlifyIdentity.off('logout', () => {
        setAuthed(getAuth());
      });
    };
  }, []);
  return (
    <>
      <div
        style={{
          margin: '5%',
          paddingLeft: '20%',
          paddingRight: '20%',
        }}
        data-netlify-identity-button
      ></div>
      {authed && (
        <div
          style={{
            paddingLeft: '20%',
            paddingRight: '20%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <button style={{ margin: '5%' }}>
            <a href='/admin'>Content Manager</a>
          </button>
          <button style={{ margin: '5%' }}>
            <a href='/orders'>Orders</a>
          </button>
          <button style={{ margin: '5%' }}>
            <a href='/inventory'>Inventory</a>
          </button>
        </div>
      )}
    </>
  );
}
