import "./About.css";
import { attributes, react as AboutComp } from "../../content/pages/about.md";
import Picture from "components/Picture/Picture";

function About() {
  const text = attributes;

  return (
    <div className="about">
      <div className="row-center">
        <h1>{text.title}</h1>
      </div>
      <div className="about-center">
        <Picture src={text.aboutImage} alt="about us" className="about-img" />
        <AboutComp />
      </div>
    </div>
  );
}

export default About;
