import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReCaptchaV2 from "react-google-recaptcha";
import "./ContactForm.css";
import Loader from "components/Picture/Loader";
import { attributes } from "../../content/components/contact.md";

function Contact() {
  const text = attributes;
  const [state, setState] = useState({
    from: null,
    subject: null,
    message: null,
    success: null,
    loading: undefined,
    token: null,
    contact: null,
  });

  const reRef = useRef(ReCaptchaV2);

  const formSubmit = async (formData) => {
    setState((prevState) => ({ ...prevState, loading: true, success: null }));

    const token = await reRef.current.getValue();
    reRef.current.reset();

    const response = await fetch("/.netlify/functions/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        to: "white.wings.studio22@gmail.com",
        subject: `From: ${formData.email} - Subject: ${formData.subject}`,
        email: formData.email,
        name: state.name,
        message: state.message,
        token: token,
      }),
    });
    setState((prevState) => ({
      ...prevState,
      loading: false,
      success: response.status,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="contact-form">
      <div>
        <form id="contactForm" onSubmit={handleSubmit(formSubmit)}>
          <div className="row">
            <input
              name="email"
              type="text"
              placeholder={text.email}
              {...register("email", {
                required: true,
                pattern:
                  /^[A-Za-z0-9]{1,}@{1}[A-Za-z0-9]{2,}\.{1}[A-Za-z0-9]{2,5}$/gm,
              })}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  from: e.target.value,
                }))
              }
            />
          </div>
          <div className="row-center">
            {errors.email?.type === "required" && "Email is required"}
            {errors.email?.type === "pattern" && "Please provide a valid email"}
          </div>
          <div className="row">
            <input
              name="name"
              type="text"
              placeholder="Name"
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className="row">
            <input
              name="subject"
              type="text"
              {...register("subject", { required: true })}
              placeholder={text.subject}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }))
              }
            />
          </div>
          <div className="row-center">
            {errors.subject?.type === "required" && "Subject is required"}
          </div>
          <div className="row">
            <textarea
              name="message"
              rows="5"
              placeholder={text.message}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  message: e.target.value,
                }))
              }
              value={state.message ?? ""}
            />
          </div>
          <div className="recap row">
            <ReCaptchaV2
              ref={reRef}
              sitekey={process.env.REACT_APP_RE_SITE_KEY}
              onChange={(token) => {
                setState((currentForm) => {
                  return { ...currentForm, token };
                });
              }}
              onExpired={() => {
                setState((currentForm) => {
                  return { ...currentForm, token: null };
                });
              }}
            />
          </div>
          <div className="submit row-center">
            <input type="submit" disabled={state.loading} value="Send" />
          </div>
          <div className="row-center">
            {state.success !== null
              ? {
                  200: (
                    <label style={{ color: "green" }}>
                      {text.response.success}
                    </label>
                  ),
                  401: (
                    <label style={{ color: "red" }}>
                      {text.response.recaptcha}
                    </label>
                  ),
                  500: (
                    <label style={{ color: "red" }}>
                      {text.response.error}
                    </label>
                  ),
                }[state.success]
              : null}
          </div>
        </form>
        <div className="row-center">{state.loading ? <Loader /> : null}</div>
      </div>
    </div>
  );
}
export default Contact;
