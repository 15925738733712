import Picture from "components/Picture/Picture";
import "./ImageSelect.css";

export default function ImageSelect(props) {
  const images = require
    .context("../../../public/images", false, /\.*$/)
    .keys();
  return (
    <div className="row-wrap image-select">
      <div id="myModal" className="modal">
        <div className="modal-content">
          <div
            onClick={() => {
              props.close();
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </div>
          {images.map((image) => (
            <Picture
              onClick={() => {
                props.select(image.replace("./", "images/"));
                props.close();
              }}
              src={image.replace("./", "./images/")}
              alt="select"
            />
          ))}
          <div className="row-center">
            <button
              onClick={() => {
                props.close();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
