import Picture from "components/Picture/Picture";
import "./Collage.css";

export default function Collage(props) {
  return (
    <div className="gallery-grid">
      {props.imgs.map((img) => (
        <div className="gallery-container">
          <Picture
            src={img.src}
            srcHover={img.hover}
            hoverClassName="gallery-container"
            alt="collage item"
            style={img.style}
          />
        </div>
      ))}
    </div>
  );
}
