import axios from "axios";
import Loader from "components/Picture/Loader";
import Product from "components/Product/Product";
import { Fragment, useEffect, useState } from "react";
import getAuth from "shared/http/getAuth";
import "./Orders.css";

export default function Orders() {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState([]);

  const getOrders = () => {
    setLoading(true);
    axios
      .get("/.netlify/functions/orders", {
        headers: { authorization: getAuth() },
      })
      .then((res) => {
        setOrders(res.data);
        setLoading(false);
      })
      .catch((err) => setOrders([]));
  };

  useEffect(() => {
    getOrders();
    window.netlifyIdentity.on("login", getOrders);
    window.netlifyIdentity.on("logout", getOrders);

    return () => {
      window.netlifyIdentity.off("login", getOrders);
      window.netlifyIdentity.off("logout", getOrders);
    };
  }, []);

  return (
    <Fragment>
      <div style={{ margin: "5%" }} data-netlify-identity-button></div>
      {loading ? (
        <div className="row-center">
          <Loader />
        </div>
      ) : (
        orders && (
          <div className="orders">
            <h2>Un-Fufilled</h2>
            <div className="row row-wrap">
              {orders.unFufilled.map((o) => {
                return (
                  <Order getOrders={getOrders} showFufill={true} order={o} />
                );
              })}
            </div>
            <hr />
            <h2>Fufilled</h2>
            <div className="column" style={{ margin: "2%", width: "46%" }}>
              {orders.fufilled.map((o) => {
                return (
                  <Fragment>
                    <button
                      style={{ width: "50%" }}
                      onClick={() => {
                        if (show.find((s) => o._id === s)) {
                          setShow((prevState) => {
                            return prevState.filter((s) => s !== o._id);
                          });
                        } else {
                          setShow((prevState) => {
                            return [...prevState, o._id];
                          });
                        }
                        setOrders(orders);
                      }}
                    >
                      {show.find((s) => o._id === s) ? "Hide" : "Show"} order
                      for {o.customerInfo.email}
                    </button>
                    <div
                      style={{
                        display: show.find((s) => o._id === s)
                          ? "block"
                          : "none",
                      }}
                    >
                      <Order width="100%" order={o} />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        )
      )}
    </Fragment>
  );
}

function Order(props) {
  const o = props.order;
  return (
    <div
      style={{
        width: props.width ?? "48%",
        wordBreak: "break-word",
        borderWidth: "2px",
        border: "solid",
        padding: "1%",
        margin: "1%",
      }}
      className="column"
    >
      <div className="row">
        <label>Order Id:</label>
        <span>{o._id}</span>
      </div>
      <div className="row">
        <label>Confirmation number:</label>
        <span>{o.sessionId}</span>
      </div>
      <div className="row">
        <label>Customer email: </label>
        <span>{o.customerInfo.email}</span>
      </div>
      <div className="row">
        <label>Shipping:</label>
        <div className="column" style={{ width: "75%" }}>
          <span>{o.shippingInfo.name}</span>
          <span>{o.shippingInfo.address.line1}</span>
          <span>{o.shippingInfo.address.line2}</span>
          <span>
            {o.shippingInfo.address.city}, {o.shippingInfo.address.state}{" "}
            {o.shippingInfo.address.postal_code}
          </span>
          <span>{o.shippingInfo.address.country}</span>
        </div>
      </div>
      <div className="row">
        <label>Price Total (&#162;):</label>
        <span>{o.session.amount_total}</span>
      </div>
      <div className="row">
        <label>Products:</label>
        <div className="column" style={{ width: "50%" }}>
          <div className="row row-wrap">
            {o.products.map((p) => {
              return <Product product={p} />;
            })}
          </div>
        </div>
      </div>

      {props.showFufill && (
        <div className="row-center">
          <button
            style={{ width: "50%" }}
            onClick={() => {
              if (
                !window.confirm("Are you sure you want to fufill this order?")
              )
                return;
              o.isFufilled = true;
              axios
                .put("/.netlify/functions/orders", o, {
                  headers: { authorization: getAuth() },
                })
                .then((res) => props.getOrders());
            }}
          >
            Fufill order
          </button>
        </div>
      )}
    </div>
  );
}
