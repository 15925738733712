import Picture from "components/Picture/Picture";
import { useState } from "react";
import "./Product.css";
import ProductModal from "./ProductModal";

export default function Product(props) {
  const product = props.product;

  const [modal, setModal] = useState(false);

  const isInCart = () => {
    const current = JSON.parse(window.localStorage.getItem("cart")) ?? [];
    return current.find((c) => c === product._id);
  };

  const [inCart, setInCart] = useState(isInCart());

  const addToCart = () => {
    if (!isInCart()) {
      const current = JSON.parse(window.localStorage.getItem("cart")) ?? [];
      current.push(product._id);
      window.localStorage.setItem("cart", JSON.stringify(current));
    }
    setInCart(isInCart);
    window.dispatchEvent(new CustomEvent("cartUpdate", {}));
  };

  const removeFromCart = () => {
    if (isInCart()) {
      let current = JSON.parse(window.localStorage.getItem("cart")) ?? [];
      current = current.filter((c) => c !== product._id);
      window.localStorage.setItem("cart", JSON.stringify(current));
    }
    setInCart(isInCart);
    window.dispatchEvent(new CustomEvent("cartUpdate", {}));
  };

  return (
    <div className="product">
      {product ? (
        <div>
          <div className="row">
            <Picture
              onClick={() => {
                setModal(!modal);
              }}
              src={product.image}
              alt="product"
              style={{
                width: "100%",
                maxHeight: "auto",
                paddingLeft: "25%",
                paddingRight: "25%",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="row-center">{product.title}</div>
          <div className="row-center">
            {product.quantity > 0 ? (
              product.salePrice ? (
                <div>
                  {" "}
                  <strike>${product.price}</strike>{" "}
                  <span>${product.salePrice}</span>
                </div>
              ) : (
                <span>${product.price}</span>
              )
            ) : (
              <span style={{ color: "red" }}>Sold Out</span>
            )}
          </div>

          {product.quantity > 0 || isInCart() ? (
            <div style={{ marginBottom: "2%" }} className="row-center">
              {inCart ? (
                <button onClick={removeFromCart}>Remove from cart</button>
              ) : (
                <button onClick={addToCart}>Add to cart</button>
              )}
            </div>
          ) : null}
          {modal ? (
            <ProductModal
              product={product}
              removeFromCart={removeFromCart}
              isInCart={isInCart}
              addToCart={addToCart}
              close={setModal}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
