import axios from "axios";

const getCart = (setProducts) => {
  const cart = JSON.parse(window.localStorage.getItem("cart"));
  setProducts([]);
  cart &&
    cart.forEach((c) => {
      axios.get(`/.netlify/functions/products?id=${c}`).then((res) => {
        setProducts((prevState) => [
          ...prevState,
          res.data?.length === 1 ? res.data[0] : undefined,
        ]);
      });
    });
};

export default getCart;
