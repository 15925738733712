import Picture from "./Picture";

export default function Loader() {
  return (
    <Picture
      style={{ height: "3rem" }}
      src={"images/loader.gif"}
      alt="loading"
    />
  );
}
