import { Fragment } from "react";
import Picture from "components/Picture/Picture";
export default function Categories(props) {
  const text = props.text;

  const clickHandler = (category) => {
    window.location.href = "/products?category=" + category;
  };
  return (
    <Fragment>
      <h3 className="row-center">Browse by Category</h3>
      <div className="category-section">
        <div className="column-center">
          <div
            onClick={() => clickHandler("Earings")}
            className="category-wrapper"
          >
            <Picture
              src={text.earingsImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Earings</h4>
        </div>
        <div onClick={() => clickHandler("Sets")} className="column-center">
          <div className="category-wrapper">
            <Picture
              src={text.setsImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Sets</h4>
        </div>
        <div onClick={() => clickHandler("Pendants")} className="column-center">
          <div className="category-wrapper">
            <Picture
              src={text.pendantsImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Pendants</h4>
        </div>
      </div>
      <div className="category-section">
        <div
          onClick={() => clickHandler("Bracelets")}
          className="column-center"
        >
          <div className="category-wrapper">
            <Picture
              src={text.braceletsImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Bracelets</h4>
        </div>
        <div
          onClick={() => clickHandler("Necklaces")}
          className="column-center"
        >
          <div className="category-wrapper">
            <Picture
              src={text.necklacesImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Necklaces</h4>
        </div>
        <div onClick={() => clickHandler("Rocks")} className="column-center">
          <div className="category-wrapper">
            <Picture
              src={text.rocksImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Rocks</h4>
        </div>
      </div>
      <div className="category-section">
        <div
          onClick={() => clickHandler("Framed Art")}
          className="column-center"
        >
          <div className="category-wrapper">
            <Picture
              src={text.framedArtImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Framed Art</h4>
        </div>
        <div
          onClick={() => clickHandler("Anesidora")}
          className="column-center"
        >
          <div className="category-wrapper">
            <Picture
              src={text.anesidoraImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Anesidora</h4>
        </div>
        <div onClick={() => clickHandler("Extras")} className="column-center">
          <div className="category-wrapper">
            <Picture
              src={text.extrasImage}
              alt="about us"
              className="category-img"
            />
          </div>
          <h4>Extras</h4>
        </div>
      </div>
    </Fragment>
  );
}
