import "./Footer.css";
import { Fragment } from "react";
import Picture from "components/Picture/Picture";
import { attributes } from "../../content/components/footer.md";

export default function Footer() {
  const text = attributes;
  return (
    <Fragment>
      <footer>
        <section>
          {text.quick.title ? <h6>{text.quick.title}</h6> : null}
          {text.quick.links.map((link) => (
            <div>
              <a href={link.link}>{link.text}</a>
              <hr />
            </div>
          ))}
        </section>
        <section>
          {text.middle.title ? <h6>{text.middle.title}</h6> : null}
          {text.middle.links?.map((link) => (
            <div>
              <a href={link.link}>{link.text}</a>
              <hr />
            </div>
          ))}
        </section>
        <section>
          {text.social.title ? <h6>{text.social.title}</h6> : null}
          {text.social.links.map((link) => (
            <div>
              <a rel="noreferrer" target="_blank" href={link.link}>
                <Picture src={link.pic} alt="social link" />
                {link.text}
              </a>
              <hr />
            </div>
          ))}
          {text.social.email?.text ? (
            <div>
              <a href={`mailto:${text.social.email.text}`}>
                <Picture src={text.social.email.pic} alt="mail" />
                {text.social.email.text}
              </a>
              <hr />
            </div>
          ) : null}
          {text.social.phone?.text ? (
            <div>
              <a href={`tel:${text.social.phone.text}`}>
                <Picture src={text.social.phone.pic} alt="mail" />
                {text.social.phone.text}
              </a>
              <hr />
            </div>
          ) : null}
        </section>
      </footer>
      <div className="legal">
        with ♡{" "}
        <a href="https://cjtdevs.com" rel="noreferrer" target="_blank">
          <u>cjtdevs</u>
        </a>
      </div>
      <div className="legal">
        {new Date().getFullYear()}, White Wings Studio
      </div>
    </Fragment>
  );
}
